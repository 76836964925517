import { update } from "lodash";

const userEndpoints = {
    signin: "/user/signin",
    signup: "/user/signup",
    getInfo: "/user/info",
    updatePassword: "/user/password/update",
    updateProfile: "/user/userUpdate",
  };
  
  export default userEndpoints;
  